(function () {
  'use strict';

  /* @ngdoc object
   * @name public.calendar
   * @description
   *
   */
  angular
    .module('neo.public.calendar', [
      'ui.router',
      'neo.public.calendar.championship'
    ]);
}());
